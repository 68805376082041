import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash';
import Store from 'src/stores/common-store';

const StyledDiv = styled.div`
width: 32%;
margin: 0 auto;
min-width:320px;
margin-bottom: 30px;
.heading{
	height: 130px;
	@media only screen and (max-width:1200px) {
		height: 170px;
	}
	@media only screen and (max-width:768px) {
		height: auto;
	}
}
.description-class{
	min-height: 290px;
	@media only screen and (max-width:768px) {
		height: auto;
		min-height: auto;
	}
}
.green-text{
	color:#73984a;
  }
  .light-green{
    color:#9aca38;
  }
  .text-orange{
    color:#fd6d0f;
  }
  .card-border{
    border:1px solid black;
  }
  .border-top{
    border-top: 1px solid #d8d8d8;
  }
  .card{
      flex:1;
  }

.background-orange{
    background-color:#fd6d0f;
  }
  .cartButton {
    background: #fd6d0f;
  }
`
export default function PlanCard(props) {

	let { variants } = props;

	const [flavour, setflavour] = useState('Original');
	const [adding, setadding] = useState(false);
    return (
        <StyledDiv>


		<div class="card-border flex flex-col mt-12">
		<div class="custom-height">
		<div class="green-text text-2xl font-semibold px-2 pt-4 text-left heading">
			<span>{props.heading}</span>
		</div>
		<div class="px-5 pt-1 description-class flex items-start text-left">
			<p>{props.description}</p>
		</div>
		</div>
		<div class="flex flex-col items-center border-top">

		<div class="text-4xl font-bold light-green ">
			<span style={{ color: `${props.price_text_color}` }}>{props.price}</span>
			
		</div>
		<div class="mt-2"><span>Per box</span>
		</div>
		</div>
		
		<div class="border-top text-center font-bold p-2 h-20 flex justify-center items-center">
			<p>{props.sub_heading}</p></div>

		<div class="border-top">
				<div class="flex justify-evenly items-center justify-center content-center h-12 ">
				<span class="flex items-center justify-center">
				<input
					type="radio"
					name={'radio19' + props.cardId}
					id={'test19' + props.cardId}
					checked={flavour == 'Original' ? true : false}
					onClick={() => {
					setflavour('Original');
					}}
				/>
				<label for={"test19" + props.cardId} class="text-xs font-medium tracking-widest text-center px-2 mt-2">ORIGINAL</label>
				</span>
				<span class="flex items-center justify-center">
				<input
					type="radio"
					name={'radio19' + props.cardId}
					id={'test29' + props.cardId}
					checked={flavour == 'New Flavors' ? true : false}
					onClick={() => {
					setflavour('New Flavors');
					}}
				/>
				<label for={"test29" + props.cardId} class="text-xs font-medium tracking-widest text-center px-2 mt-2">NEW FLAVORS</label>
				</span>
			</div>
			</div>
			<div class="flex justify-center items-center mb-4">
			<button
          class="text-white p-3  text-xs font-bold text-center cartButton px-8"
          onClick={async () => {
            setadding(true);
            let quantity = props.card.quantity;
            let newVariants = variants.map((e) => {
              let { selectedOptions } = e;
              if (selectedOptions && selectedOptions.length > 0) {
                selectedOptions.forEach((option) => {
                  e[option.name] = option.value;
                });
              }

              return e;
            });
            let variant = _.find(newVariants, (e) => {
              return e['Soup Flavor'] == flavour && e['Quantity'] == quantity;
            });

            let variantId = variant.shopifyId;

            let data = { variantId, quantity: 1, customAttributes: [] };
            if (props.card.subscription) {
              let attr = [
                {
                  key: 'subscription',
                  value: 'true',
                },
                {
                  key: 'shipping_interval_unit_type',
                  value: 'Months',
                },
                {
                  key: 'shipping_interval_frequency',
                  value: '1',
                },
              ];
              data.customAttributes = attr;
            }
            await Store.addItem(data);
            Store.showCart();

            setadding(false);
          }}
        > 
          {adding ? 'Adding...' : 'ADD TO CART'}
        </button>			</div> 
		</div>
        </StyledDiv>
    )
}
